import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import { ProjectLinkType } from 'config/constants/projectLinks';
import { getTopics, isActive } from 'common/utils/navigation';

import { safeGet } from 'utils/safeGet';
import { getTop100Markup } from 'common/utils/getTop100Markup';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.currentBurgerSection,
    (state: IAppState) => state.runtime.currentParams.zodiacSign,
    (state: IAppState) => state.runtime.currentParams.chineseSign,
    (state: IAppState) => state.runtime.currentParams.chineseCompatibilitySign,
  ],
  (
    currentBurgerSection,
    zodiacSign,
    chineseSign,
    chineseCompatibilitySign,
    // eslint-disable-next-line max-params
  ) => ({
    currentBurgerSection,
    zodiacSign,
    chineseSign,
    chineseCompatibilitySign,
  }),
);

interface MenuPropsType {
  top100Prefix: string;
}

function Menu({ top100Prefix }: MenuPropsType) {
  const {
    currentBurgerSection,
    zodiacSign,
    chineseSign,
    chineseCompatibilitySign,
  } = useSelector(selectData);

  const finalChineseSign = chineseSign || chineseCompatibilitySign;
  const topics = getTopics({
    currentBurgerSection,
    isMobile: true,
    zodiacSign,
    chineseSign: finalChineseSign,
  });

  const activeFirstLevelItemIndex = useMemo(
    () => topics.findIndex((item) => item.active),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [topics, currentBurgerSection],
  );

  let menu: ProjectLinkType[] | undefined = [];
  const secondLevel = safeGet(
    () => topics[activeFirstLevelItemIndex].children,
    [],
  );

  if (activeFirstLevelItemIndex !== -1 && secondLevel.length) {
    const activeSecondLevelItemIndex = secondLevel.findIndex(
      (item: ProjectLinkType) => item.active,
    );

    const thirdLevel = safeGet(
      () => secondLevel[activeSecondLevelItemIndex].children,
      [],
    );
    const combinedThirdLevel = thirdLevel.length
      ? [
          {
            ...secondLevel[activeSecondLevelItemIndex],
            active: isActive(
              currentBurgerSection,
              secondLevel[activeSecondLevelItemIndex].code,
            ),
          },
          ...thirdLevel,
        ]
      : [];

    menu = combinedThirdLevel.length ? combinedThirdLevel : secondLevel;
  }

  return (
    <div className={s.menuContainer}>
      <div className={s.menu}>
        {menu &&
          menu.map((item: ProjectLinkType) => (
            <Link
              className={cn(s.link, item.active && s.linkActive)}
              to={item.href}
              href={item.href}
              key={item.code?.toString()}
              {...getTop100Markup(
                true,
                top100Prefix,
                `top_menu::${item.title}::${item.active ? 'current' : 'not_current'}`,
              )}
            >
              {item.title}
            </Link>
          ))}
      </div>
    </div>
  );
}

export { Menu };
