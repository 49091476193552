import React from 'react';

import { fetchPageData } from 'common/redux/pages';

import { IAppRoute } from 'typings/AppRoute';

import { fetchEmoWidgetData } from 'common/redux/emojiWidget';
import { fetchVkVideoWidgetData } from 'common/redux/vkVideoWidget';

import { signNames } from 'config/constants/signNames';
import {
  date,
  zodiacSigns,
  zodiacPeriods,
  zodiacPeriodPage,
  zodiacSignPeriodPage,
  months,
  zodiacTypes,
} from 'config/constants/routes';

import DefaultPage from 'mobile/pages/Default';

import SignDescriptionPage from 'mobile/pages/Zodiac/SignDescription';
import SignDescriptionTypePage from 'mobile/pages/Zodiac/SignDescriptionType';

import CompatibilityPage from 'mobile/pages/Zodiac/Compatibility';
import SignCompatibilityPage from 'mobile/pages/Zodiac/SignCompatibility';

import { EMO_SPLIT_AS_IS } from 'config/constants/splits';

export const zodiacRoutes: IAppRoute[] = [
  // Znaki Zodiaka
  {
    name: 'znaki-zodiaka',
    path: '/znaki-zodiaka',
    exact: true,
    render: (props) => <DefaultPage {...props} withSubscribePopup />,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData(
          'horoscope',
          'description',
          {
            url: 'main',
          },
          false,
          {
            top100: 'description_main',
          },
        ),
      );
    },
  },
  {
    name: 'znaki-zodiaka-gender',
    path: '/znaki-zodiaka/:gender(man|woman)',
    exact: true,
    component: DefaultPage,
    fetchData: async ({ dispatch }, { params }) => {
      const { gender } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'description',
          {
            url: gender,
          },
          false,
          {
            top100: `description_${gender}`,
          },
        ),
      );
    },
  },
  {
    name: 'znaki-zodiaka-type',
    path: '/znaki-zodiaka/:type(stihii|kresty|in-i-yan)',
    exact: true,
    component: DefaultPage,
    fetchData: async ({ dispatch }, { params }) => {
      const { type } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'description',
          {
            url: type,
          },
          false,
          {
            top100: `description_${type}`,
          },
        ),
      );
    },
  },
  {
    name: 'znaki-zodiaka-birthstone',
    path: '/znaki-zodiaka/birthstone',
    exact: true,
    component: DefaultPage,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData(
          'horoscope',
          'birthstone',
          {
            url: 'main',
          },
          false,
          {
            top100: 'birthstone_main',
          },
        ),
      );
    },
  },
  {
    name: 'znaki-zodiaka-birthstone',
    path: `/znaki-zodiaka/birthstone/:sign(${zodiacSigns})`,
    exact: true,
    component: DefaultPage,
    fetchData: async ({ dispatch }, { params }) => {
      const { sign } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'birthstone',
          {
            url: sign,
          },
          false,
          {
            top100: `birthstone_${sign}`,
          },
        ),
      );
    },
  },
  // Sex
  {
    name: 'sex-horoscope',
    path: '/sex-horoscope',
    exact: true,
    redirect: '/sex-horoscope/today/',
  },
  {
    name: 'sex-horoscope-period',
    path: `/sex-horoscope/:period(${zodiacPeriods})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { period } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'sex',
          {
            url: `all/${period}`,
            zodiacPeriod: period,
          },
          false,
          {
            top100: `zodiac_sex_all_sign_${period}`,
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'sex-horoscope-period',
    path: `/:sign(${zodiacSigns})/sex-horoscope/:period(${zodiacPeriods})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { sign, period } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'sex',
          {
            url: `${sign}/${period}`,
            zodiacSign: sign,
            zodiacPeriod: period,
          },
          false,
          {
            top100: `zodiac_sex_sign_${period}`,
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  // woman
  {
    name: 'woman-period',
    path: '/woman/today',
    exact: true,
    component: DefaultPage,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData(
          'horoscope',
          'woman',
          {
            url: 'main',
          },
          false,
          {
            top100: 'zodiac_woman_main',
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'sign-woman',
    path: `/:sign(${zodiacSigns})/woman`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { sign } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'woman',
          {
            url: `${sign}/today`,
            zodiacSign: sign,
          },
          false,
          {
            top100: 'zodiac_woman_sign',
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'sign-woman-period',
    path: `/:sign(${zodiacSigns})/woman/:period(${zodiacPeriods})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { sign, period } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'woman',
          {
            url: `${sign}/${period}`,
            zodiacSign: sign,
            zodiacPeriod: period,
          },
          false,
          {
            top100: `zodiac_woman_sign_${period}`,
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  // man
  {
    name: 'man',
    path: '/man',
    exact: true,
    component: DefaultPage,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData(
          'horoscope',
          'man',
          {
            url: 'main',
          },
          false,
          {
            top100: 'zodiac_man_main',
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'sign-man',
    path: `/:sign(${zodiacSigns})/man`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { sign } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'man',
          {
            url: `${sign}/today`,
            zodiacSign: sign,
          },
          false,
          {
            top100: 'zodiac_man_sign',
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'sign-man-period',
    path: `/:sign(${zodiacSigns})/man/:period(${zodiacPeriods})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { sign, period } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'man',
          {
            url: `${sign}/${period}`,
            zodiacSign: sign,
            zodiacPeriod: period,
          },
          false,
          {
            top100: `zodiac_man_sign_${period}`,
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'sign',
    path: `/:sign(${zodiacSigns})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch, getState }, { params }) => {
      const { sign } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'general',
          {
            url: `${sign}/today`,
            zodiacSign: sign,
          },
          false,
          {
            top100: 'zodiac_general_sign_today',
            isBubblesMoved: true,
          },
        ),
      );

      const { runtime } = getState();

      const widgetSplitValue = runtime.splits.emoji_widget || EMO_SPLIT_AS_IS;
      const widgetIsActive = widgetSplitValue !== EMO_SPLIT_AS_IS;

      // делаем запрос только когда попали в активный сплит
      if (widgetIsActive) {
        await dispatch(fetchEmoWidgetData({ sign }));
      }

      const vkWidgetEnable = runtime.adminData.settings.vk_video_promo;

      // делаем запрос только когда виджет включен в админке
      if (vkWidgetEnable) {
        await dispatch(fetchVkVideoWidgetData(sign));
      }
    },
  },
  {
    name: 'period',
    path: `/:period(${zodiacPeriodPage})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { period } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'general',
          {
            url: `all/${period}`,
            zodiacPeriod: period,
          },
          false,
          {
            top100: `zodiac_general_all_sign_${period}`,
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'general-period',
    path: `/zodiac/general/all/:period(${zodiacPeriods})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch, getState }, { params }) => {
      const { period } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'general',
          {
            url: `all/${period}`,
            zodiacPeriod: period,
          },
          false,
          {
            top100: 'zodiac_general_all_sign_today',
            isBubblesMoved: true,
          },
        ),
      );

      if (period === 'today') {
        const { runtime } = getState();
        const vkWidgetEnable = runtime.adminData.settings.vk_video_promo;

        // делаем запрос только когда виджет включен в админке
        if (vkWidgetEnable) {
          await dispatch(fetchVkVideoWidgetData());
        }
      }
    },
  },
  {
    name: 'general-next-week',
    path: '/next-week',
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData(
          'horoscope',
          'general',
          {
            url: 'all/next_week',
            zodiacPeriod: 'next-week',
          },
          false,
          {
            top100: 'zodiac_general_all_sign_next_week',
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'sign-next-week',
    path: `/:sign(${zodiacSigns})/next-week`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { sign } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'general',
          {
            url: `${sign}/next_week`,
            zodiacSign: sign,
            zodiacPeriod: 'next-week',
          },
          false,
          {
            top100: 'zodiac_sign_next_week',
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'compatibility',
    path: '/sovmestimost-znakov-zodiaka',
    exact: true,
    component: CompatibilityPage,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData(
          'horoscope',
          'compatibility',
          {
            url: 'all',
          },
          false,
          {
            top100: '',
          },
        ),
      );
    },
  },
  {
    name: 'sign-compatibility',
    path: `/:sign(${zodiacSigns})/sovmestimost-znakov-zodiaka`,
    exact: true,
    component: SignCompatibilityPage,
    fetchData: async ({ dispatch }, { params }) => {
      const { sign } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'compatibility',
          {
            url: sign,
            zodiacSign: sign,
          },
          false,
          {
            top100: '',
          },
        ),
      );
    },
  },
  {
    name: 'compatibility-pairs',
    path: '/sovmestimost-znakov-zodiaka/zhenshhina-:woman-muzhchina-:man',
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema withSubscribePopup />,
    fetchData: async ({ dispatch }, { params }) => {
      const { woman, man } = params as {
        woman: keyof typeof signNames.zodiacLatin;
        man: keyof typeof signNames.zodiacLatin;
      };

      await dispatch(
        fetchPageData(
          'horoscope',
          'compatibility',
          {
            url: `woman-${signNames.zodiacLatin[woman]}/man-${signNames.zodiacLatin[man]}`,
            zodiacCompatibilityWomanSign: signNames.zodiacLatin[woman],
            zodiacCompatibilityManSign: signNames.zodiacLatin[man],
          },
          false,
          {
            top100: '',
          },
        ),
      );
    },
  },
  {
    name: 'date',
    path: `/:year(${date.year})-:month(${date.month})-:day(${date.day})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { year, month, day } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'general',
          {
            url: `all/${year}-${month}-${day}`,
            zodiacDate: `${year}-${month}-${day}`,
          },
          false,
          {
            top100: 'zodiac_general_all_sign_date',
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'month',
    path: `/:month(${months})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { month } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'general',
          {
            url: `all/${month}`,
            zodiacMonth: month,
          },
          false,
          {
            top100: 'zodiac_general_all_sign_some_month',
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'sign-description',
    path: `/:sign(${zodiacSigns})/description`,
    exact: true,
    component: SignDescriptionPage,
    fetchData: async ({ dispatch }, { params }) => {
      const { sign } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'description',
          {
            url: sign,
            zodiacSign: sign,
          },
          false,
          {
            top100: 'description_common',
          },
        ),
      );
    },
  },
  {
    name: 'sign-description-type',
    path: `/:sign(${zodiacSigns})/description/:type(${zodiacTypes})`,
    exact: true,
    // @ts-expect-error: Types of property 'match' are incompatible
    render: (props) => <SignDescriptionTypePage {...props} />,
    fetchData: async ({ dispatch }, { params }) => {
      const { sign, type } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'description',
          {
            url: `${sign}/${type}`,
            zodiacSign: sign,
            zodiacDescriptionType: type,
          },
          false,
          {
            top100: `description_${type}`,
          },
        ),
      );
    },
  },
  // Love
  {
    name: 'all-erotic',
    path: '/erotic',
    exact: true,
    component: DefaultPage,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData(
          'horoscope',
          'love',
          {
            url: 'main',
          },
          false,
          {
            top100: 'zodiac_love_main',
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'sign-erotic',
    path: `/:sign(${zodiacSigns})/erotic`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { sign } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'love',
          {
            url: `${sign}/today`,
            zodiacSign: sign,
          },
          false,
          {
            top100: 'zodiac_love_sign_today',
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'sign-erotic-period',
    path: `/:sign(${zodiacSigns})/erotic/:period(${zodiacPeriods})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { sign, period } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'love',
          {
            url: `${sign}/${period}`,
            zodiacSign: sign,
            zodiacPeriod: period,
          },
          false,
          {
            top100: `zodiac_love_sign_${period}`,
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'love-all-period',
    path: `/zodiac/love/all/:period(${zodiacPeriods})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { period } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'love',
          {
            url: `all/${period}`,
            zodiacPeriod: period,
          },
          false,
          {
            top100: `zodiac_love_all_signs_${period}`,
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  // Finance
  {
    name: 'all-career',
    path: '/career',
    exact: true,
    component: DefaultPage,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData(
          'horoscope',
          'finance',
          {
            url: 'main',
          },
          false,
          {
            top100: 'zodiac_finance_main',
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'sign-career',
    path: `/:sign(${zodiacSigns})/career`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { sign } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'finance',
          {
            url: `${sign}/today`,
            zodiacSign: sign,
          },
          false,
          {
            top100: 'zodiac_finance_sign_today',
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'sign-career-period',
    path: `/:sign(${zodiacSigns})/career/:period(${zodiacPeriods})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { sign, period } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'finance',
          {
            url: `${sign}/${period}`,
            zodiacSign: sign,
            zodiacPeriod: period,
          },
          false,
          {
            top100: `zodiac_finance_sign_${period}`,
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'finance-all-period',
    path: `/zodiac/finance/all/:period(${zodiacPeriods})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { period } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'finance',
          {
            url: `all/${period}`,
            zodiacPeriod: period,
          },
          false,
          {
            top100: `zodiac_finance_all_signs_${period}`,
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'sign-date',
    path: `/:sign(${zodiacSigns})/:year-:month-:day`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { sign, year, month, day } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'general',
          {
            url: `${sign}/${year}-${month}-${day}`,
            zodiacSign: sign,
            zodiacDate: `${year}-${month}-${day}`,
          },
          false,
          {
            top100: 'zodiac_general_sign_date',
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'sign-month',
    path: `/:sign(${zodiacSigns})/:month(${months})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { sign, month } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'general',
          {
            url: `${sign}/${month}`,
            zodiacSign: sign,
            zodiacMonth: month,
          },
          false,
          {
            top100: 'zodiac_general_sign_some_month',
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'sign-period',
    path: `/:sign(${zodiacSigns})/:period(${zodiacSignPeriodPage})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { sign, period } = params;

      await dispatch(
        fetchPageData(
          'horoscope',
          'general',
          {
            url: `${sign}/${period}`,
            zodiacSign: sign,
            zodiacPeriod: period,
          },
          false,
          {
            top100: `zodiac_general_sign_${period}`,
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
];
