import React from 'react';
import { useSelector } from 'react-redux';

import { CardHeader } from 'common/components/Card/Header';
import { Footer } from 'common/components/Card/Footer';
import { Ad } from 'common/components/Ad';
import { FORTUNES } from 'config/constants/tarot';
import { FortunePreview } from 'common/components/Tarot/FortunePreview';
import DefaultPage from 'mobile/pages/Default';
import { Tags } from 'common/components/Card/Tags';
import { parseDraft } from 'common/utils/draftParser';
import { FeedbackForm } from 'common/components/FeedbackForm';
import { DraftText } from 'common/components/DraftText';

import { selectPageData } from 'utils/selectPageData';

import s from './styles.css';

function TarotOnlinePage() {
  const data = useSelector((state: IAppState) =>
    selectPageData(state, 'fortune', 'taroonline'),
  );

  if (!data) return null;

  const { content } = data;
  const isMobile = true;

  const { title, link, likes, type, draft, tags, id } = content;

  const draftParsed = draft
    ? parseDraft({
        draftStateInRow: draft,
        id,
        isMobile,
        withAnchors: true,
      })
    : '';

  return (
    <DefaultPage
      withSchema
      withoutMainCard
      withTarotMenu
      centerColumn={
        <>
          <div className={s.card}>
            <CardHeader
              isMobile={isMobile}
              titleProps={{
                title,
                isMobile,
                type,
                isLink: false,
                isMainCard: true,
                isH1Title: true,
                isHanding: false,
              }}
              socialProps={{
                isMobile,
              }}
            />
            <DraftText
              data={draftParsed}
              title={title}
              className={s.text}
              isMainCard
            />
            <ul className={s.list}>
              {FORTUNES.map((fortune) => (
                <li key={fortune}>
                  <FortunePreview isMobile={isMobile} type={fortune} />
                </li>
              ))}
            </ul>
            <Tags wrapperClassName={s.tags} tags={tags} isMobile={isMobile} />
            <Footer
              title={title}
              link={link}
              likes={likes}
              isMobile
              key={likes ? likes.codename : ''}
            />
          </div>

          <Ad name="top_banner" wrapperClassName={s.ad} />

          <FeedbackForm isCard />
        </>
      }
    />
  );
}

export default TarotOnlinePage;
