import React from 'react';

import { IAppRoute } from 'typings/AppRoute';
import { fetchPageData } from 'common/redux/pages';

import NewsLetter from 'mobile/pages/NewsLetter';

export const SET_PAGE_DATA = 'SET_PAGE_DATA';

export const newsLetterRoutes: IAppRoute[] = [
  {
    name: 'news-letter',
    path: '/news-letter',
    exact: true,
    render: () => <NewsLetter />,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData('mailing', 'main', undefined, false, {
          top100: 'news_letter_main',
        }),
      );
    },
  },
];
