import React from 'react';

import {
  routeLetters,
  date,
  months,
  namesTypes,
  namesTypesExtended,
  namesCategoriesRoutes,
  namesCategoriesExtendedRoutes,
} from 'config/constants/routes';

import { fetchPageData } from 'common/redux/pages';

import { IAppRoute } from 'typings/AppRoute';

import DefaultPage from 'mobile/pages/Default';

const typeTransform = (type: 'male' | 'female' | 'baby') =>
  type === 'male' ? 'masculine' : 'feminine';

export const namesRoutes: IAppRoute[] = [
  {
    name: 'names',
    path: '/names',
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData('names', 'main', undefined, false, {
          top100: 'names_interpretation_main_words',
          isMenuBubblesHidden: true,
        }),
      );
    },
  },
  {
    name: 'names-word',
    path: '/names/name/:word',
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { word } = params;

      await dispatch(
        fetchPageData(
          'names',
          'name',
          {
            url: word,
            namesWord: word,
          },
          false,
          {
            top100: 'names_interpretation_word_page',
            isMenuBubblesHidden: true,
          },
        ),
      );
    },
  },
  {
    name: 'names-letter',
    path: `/names/letter/:letter(${routeLetters})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { letter } = params;

      await dispatch(
        fetchPageData(
          'names',
          'letters',
          {
            url: `letter/${letter}`,
            namesLetter: letter,
          },
          false,
          {
            top100: 'names_interpretation_letter_page',
            isMenuBubblesHidden: true,
          },
        ),
      );
    },
  },
  {
    name: 'names-types-letter',
    path: `/names/:type(male|female)/letter/:letter(${routeLetters})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { type, letter } = params;

      await dispatch(
        fetchPageData(
          'names',
          'letters',
          {
            url: `letter/${letter}/${typeTransform(type)}`,
            namesLetter: letter,
          },
          false,
          {
            top100: 'names_interpretation_letter_page',
            isMenuBubblesHidden: true,
          },
        ),
      );
    },
  },
  {
    name: 'names-calendar-main',
    path: '/names/calendar',
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData('names', 'calendar', undefined, false, {
          top100: 'nameday_main',
          isMenuBubblesHidden: true,
        }),
      );
    },
  },
  {
    name: 'names-calendar-month',
    path: `/names/calendar/:month(${months})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { month } = params;

      await dispatch(
        fetchPageData(
          'names',
          'month',
          {
            url: month,
          },
          false,
          {
            top100: 'names_period_month',
            isMenuBubblesHidden: true,
          },
        ),
      );
    },
  },
  {
    name: 'names-calendar-date',
    path: `/names/calendar/:year(${date.year})-:month(${date.month})-:day(${date.day})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { year, month, day } = params;

      await dispatch(
        fetchPageData(
          'names',
          'date',
          {
            url: `${year}-${month}-${day}`,
            moonDate: `${year}-${month}-${day}`,
          },
          false,
          {
            top100: 'names_period_date',
            isMenuBubblesHidden: true,
          },
        ),
      );
    },
  },
  {
    name: 'names-patronymic',
    path: '/names/patronymic/:type(male|female)',
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { type } = params;

      await dispatch(
        fetchPageData(
          'names',
          'patronymic',
          {
            url: typeTransform(type),
            namesPatronymic: type,
          },
          false,
          {
            top100: 'names_interpretation_letter_page',
            isMenuBubblesHidden: true,
          },
        ),
      );
    },
  },
  {
    name: 'names-types-extended',
    path: `/names/:type(${namesTypesExtended})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { type } = params;

      const agePart = ['baby', 'boys', 'girls'].includes(type)
        ? 'child'
        : 'adult';
      const genderPart = ['male', 'boys'].includes(type)
        ? 'masculine'
        : 'feminine';
      const genderPartForAdults = type !== 'baby' ? `/${genderPart}` : '';

      await dispatch(
        fetchPageData(
          'names',
          'category',
          {
            url: `${agePart}${genderPartForAdults}`,
            namesType: type,
          },
          false,
          {
            top100: 'names_interpretation_letter_page',
            isMenuBubblesHidden: true,
          },
        ),
      );
    },
  },
  {
    name: 'names-category',
    path: `/names/:category(${namesCategoriesExtendedRoutes})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { category } = params;

      await dispatch(
        fetchPageData(
          'names',
          'category',
          {
            url: category,
            namesCategory: category,
          },
          false,
          {
            top100: 'names_category_page',
            isMenuBubblesHidden: true,
          },
        ),
      );
    },
  },
  {
    name: 'names-type-category',
    path: `/names/:type(${namesTypes})/:category(${namesCategoriesRoutes})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { type, category } = params;
      const agePart = type === 'baby' ? 'child/' : 'adult/';
      const genderPart = type === 'baby' ? '' : `${typeTransform(type)}/`;

      await dispatch(
        fetchPageData(
          'names',
          'category',
          {
            url: `${agePart}${genderPart}${category}`,
            namesType: type,
            namesCategory: category,
          },
          false,
          {
            top100: 'names_type_category_page',
            isMenuBubblesHidden: true,
          },
        ),
      );
    },
  },
];
