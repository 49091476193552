import React from 'react';

import { date } from 'config/constants/routes';

import { fetchPageData } from 'common/redux/pages';

import { IAppRoute } from 'typings/AppRoute';

import DefaultPage from 'mobile/pages/Default';

export const personalRoutes: IAppRoute[] = [
  {
    name: 'personal',
    path: '/personal',
    exact: true,
    component: DefaultPage,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData('dashboard', 'main', undefined, false, {
          top100: 'dashboard_main',
          isMenuBubblesHidden: true,
        }),
      );
    },
  },
  {
    name: 'personal-date',
    path: `/personal/:year(${date.year})-:month(${date.month})-:day(${date.day})`,
    exact: true,
    component: DefaultPage,
    fetchData: async ({ dispatch }, { params }) => {
      const { year, month, day } = params;

      await dispatch(
        fetchPageData(
          'dashboard',
          'main',
          {
            url: `${year}-${month}-${day}`,
            personalDateOfPrediction: `${year}-${month}-${day}`,
          },
          false,
          {
            top100: 'dashboard_date',
            isMenuBubblesHidden: true,
          },
        ),
      );
    },
  },
  {
    name: 'personal-date-birthday',
    path: `/personal/:pYear(${date.year})-:pMonth(${date.month})-:pDay(${date.day})/:bYear(${date.year})-:bMonth(${date.month})-:bDay(${date.day})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSubscribePopup />,
    fetchData: async ({ dispatch }, { params }) => {
      const { pYear, pMonth, pDay, bYear, bMonth, bDay } = params;

      const personalDateOfPrediction = `${pYear}-${pMonth}-${pDay}`;
      const personalDateOfBirth = `${bYear}-${bMonth}-${bDay}`;

      await dispatch(
        fetchPageData(
          'dashboard',
          'main',
          {
            url: `${personalDateOfPrediction}/birthdate/${personalDateOfBirth}`,
            personalDateOfPrediction,
            personalDateOfBirth,
          },
          false,
          {
            top100: 'dashboard_date_birthdate',
            isMenuBubblesHidden: true,
          },
        ),
      );
    },
  },
  {
    name: 'personal-celeb',
    path: '/personal/:celeb',
    exact: true,
    component: DefaultPage,
    fetchData: async ({ dispatch }, { params }) => {
      const { celeb } = params;

      await dispatch(
        fetchPageData(
          'dashboard',
          'main',
          {
            url: `${celeb}`,
            personalCelebritySlug: `${celeb}`,
          },
          false,
          {
            top100: 'dashboard_celebrity',
            isMenuBubblesHidden: true,
          },
        ),
      );
    },
  },
  {
    name: 'personal-celeb-date',
    path: `/personal/:celeb/:year(${date.year})-:month(${date.month})-:day(${date.day})`,
    exact: true,
    component: DefaultPage,
    fetchData: async ({ dispatch }, { params }) => {
      const { celeb, year, month, day } = params;

      await dispatch(
        fetchPageData(
          'dashboard',
          'main',
          {
            url: `${year}-${month}-${day}/${celeb}`,
            personalDateOfPrediction: `${year}-${month}-${day}`,
            personalCelebritySlug: `${celeb}`,
          },
          false,
          {
            top100: 'dashboard_celebrity_date',
            isMenuBubblesHidden: true,
          },
        ),
      );
    },
  },
];
