import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { parseDraft } from 'common/utils/draftParser';

import { DraftText } from 'common/components/DraftText';
import { CardHeader } from 'common/components/Card/Header';
import { Footer } from 'common/components/Card/Footer';
import { Tags } from 'common/components/Card/Tags';
import { Typography } from 'common/components/Typography';
import { HumanDesignTypes } from 'common/components/HumanDesign/Types';
import { HumanDesignProfiles } from 'common/components/HumanDesign/Profiles';
import { HumanDesignForm } from 'common/components/HumanDesign/Form';
import { HumanDesignHighlight } from 'common/components/HumanDesign/Highlight';
import { HumanDesignInterpretation } from 'common/components/HumanDesign/Interpretation';
import { HumanDesignBodygraph } from 'common/components/HumanDesign/Bodygraph';
import { HumanDesignEnergyCenters } from 'common/components/HumanDesign/EnergyCenters';
import { HumanDesignPartnerResult } from 'common/components/HumanDesign/PartnerResult';
import { HumanDesignError } from 'common/components/HumanDesign/Error';
import { Ad } from 'common/components/Ad';

import { SEO_URLS } from 'config/constants/humanDesign';

import { safeGet } from 'utils/safeGet';
import { selectPageData } from 'utils/selectPageData';

import DefaultPage from '../../Default';

import s from './index.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.humanDesign,
    (state: IAppState) => state.runtime.currentParams.humanDesignDate,
  ],
  (humanDesign, humanDesignDate) => ({
    humanDesign,
    humanDesignDate,
  }),
);

function HumanDesignResultPage() {
  const { humanDesign, humanDesignDate } = useSelector(selectData);
  const data = useSelector((state: IAppState) =>
    selectPageData(state, 'humandesign', 'result'),
  );

  const humanDesignInterpretation = safeGet(
    () => humanDesign.result.interpretation,
    { type: '' },
  );
  const humanDesignDetailed = safeGet(
    () => SEO_URLS[humanDesignInterpretation.type.toLowerCase()],
    '',
  );
  const currentType = humanDesign.detailed.types[humanDesignDetailed];

  if (!data) {
    return null;
  }

  const { content } = data;
  const isMobile = true;
  const { timezone, place_id: place } = humanDesign.places.details || {};

  const {
    title,
    link,
    type,
    is_h2_title: isH2Title,
    is_h3_title: isH3Title,
    isMainCard,
    isSubCard,
    tags,
    likes,
  } = content;

  const currentLink = link;

  if (currentLink && currentLink.link === 'self') {
    currentLink.link = `/dizain-cheloveka/karta/?date=${humanDesignDate}&timezone=${timezone}&place=${place}`;
  }

  const adShift = 2;

  const draftParsed =
    currentType && currentType.draft
      ? parseDraft({
          draftStateInRow: currentType.draft,
          id: 'result-type',
          isMobile,
          adShift,
        })
      : '';
  const cardText = (
    <DraftText
      data={draftParsed}
      title={title}
      className={cn(s.draftText, s.draftTextMobile)}
      isMainCard
    />
  );

  return (
    <DefaultPage
      withSchema
      withoutMainCard
      forceFirstTopBanner
      centerColumn={
        <div className={s.card}>
          {humanDesign.result && humanDesign.result.interpretation ? (
            <>
              <CardHeader
                isMobile={isMobile}
                titleProps={{
                  title,
                  isMobile,
                  link,
                  type,
                  isLink: false,
                  isH2Title,
                  isH3Title,
                  isMainCard,
                  isH1Title: true,
                  isHanding: false,
                  isSubCard,
                }}
                socialProps={{
                  links: {},
                  isMobile,
                }}
              />
              {humanDesign.places.details && (
                <>
                  <HumanDesignHighlight
                    details={humanDesign.places.details}
                    humanDesignDate={humanDesignDate}
                    className={s.highlight}
                    isMobile
                  />
                  {currentType && (
                    <Typography
                      variant="defaultRegular"
                      component="p"
                      className={s.description}
                    >
                      {currentType.short_description}
                    </Typography>
                  )}
                  <Ad name="content1" wrapperClassName={s.ad} />
                </>
              )}
              <HumanDesignInterpretation
                interpretation={humanDesign.result.interpretation}
                isMobile
              />
              <Ad name="inpage_m" wrapperClassName={s.ad} />
              <HumanDesignBodygraph
                bodygraphData={humanDesign.result}
                isMobile
              />
              <HumanDesignEnergyCenters
                definedCenters={humanDesign.result.defined_centers}
                isMobile
              />
              {cardText}
              <HumanDesignPartnerResult
                humanDesignDate={humanDesignDate}
                place={place}
                isMobile
              />
            </>
          ) : (
            <HumanDesignError isMobile={isMobile} />
          )}
          <Ad name="content4" wrapperClassName={s.ad} />
          <HumanDesignTypes types={humanDesign.types} limit={2} isMobile />
          <Ad name="content4" wrapperClassName={s.ad} />
          <HumanDesignProfiles
            profiles={humanDesign.profiles}
            limit={2}
            isMobile
          />
          <HumanDesignForm isMobile isExperimental={false} />
          <Tags wrapperClassName={s.tags} tags={tags} isMobile={isMobile} />
          <Footer
            className={cn(s.footer, s.footerMobile)}
            likesClassName={cn(s.likes, s.likesMobile)}
            title={title}
            link={link}
            likes={likes}
            isMobile
            key={likes ? likes.codename : ''}
          />
        </div>
      }
    />
  );
}

export default HumanDesignResultPage;
