import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { ToplineMobile } from 'common/components/ToplineMobile';
import { Ad } from 'common/components/Ad';
import { SignBubbles } from 'common/components/Card/SignBubbles';
import { PersonalAccount } from 'common/components/PersonalAccount';
import { Notifications } from 'common/components/Notifications';
import { RetrogradeMercuryAlert } from 'common/components/Alerts/RetrogradeMercury';
import { Menu } from 'mobile/components/Menu';

import { Top100Context } from 'common/contexts/top100Context';

import { selectPageData } from 'utils/selectPageData';

import { STRICT_CONTENT_PAGES } from 'config/constants/routes';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.top100,
    (state: IAppState) => state.runtime.currentPage,
    (state: IAppState) => state.runtime.currentSection,
    (state: IAppState) => state.runtime.isMenuBubblesHidden,
  ],
  (
    top100,
    currentPage,
    currentSection,
    isMenuBubblesHidden,
    // eslint-disable-next-line max-params
  ) => ({
    top100,
    currentPage,
    currentSection,
    isMenuBubblesHidden,
  }),
);

function TopPart() {
  const { top100, currentPage, currentSection, isMenuBubblesHidden } =
    useSelector(selectData);
  const { content, bubbles } = useSelector((state: IAppState) =>
    selectPageData(state, currentPage, currentSection),
  );

  const top100Prefix = `${top100}_${content ? 'content_page' : 'list_page'}`;
  const top100PrefixMemo = useMemo(() => ({ top100Prefix }), [top100Prefix]);

  if (STRICT_CONTENT_PAGES.includes(currentPage)) {
    return (
      <>
        <ToplineMobile />
        <div className={s.fixedWidth}>
          <Menu top100Prefix={top100Prefix} />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={s.fixedWidth}>
        <Notifications />
        <Ad name="fullscreen_m" />
        <Ad name="rich" />
        <Ad name="Promovidzhetmob" />
      </div>
      <ToplineMobile />
      <div className={s.fixedWidth}>
        <Ad name="top_banner" isFirstBillboardOrTopBanner />
        <Menu top100Prefix={top100Prefix} />
        <RetrogradeMercuryAlert
          className={s.retrogradeMercuryAlert}
          view="vertical"
          isMobile
        />
        {!isMenuBubblesHidden && (
          <Top100Context.Provider value={top100PrefixMemo}>
            <SignBubbles className={s.bubbles} bubbles={bubbles || []} />
          </Top100Context.Provider>
        )}
        <PersonalAccount
          className={cn(
            bubbles && !isMenuBubblesHidden && s.personalAccountWithBubbles,
          )}
          top100Prefix={top100Prefix}
        />
      </div>
    </>
  );
}

export { TopPart };
