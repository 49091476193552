import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { parseDraft } from 'common/utils/draftParser';

import { DraftText } from 'common/components/DraftText';
import { CardHeader } from 'common/components/Card/Header';
import { Tags } from 'common/components/Card/Tags';
import { Footer } from 'common/components/Card/Footer';
import { HumanDesignTypes } from 'common/components/HumanDesign/Types';
import { HumanDesignProfiles } from 'common/components/HumanDesign/Profiles';
import { HumanDesignForm } from 'common/components/HumanDesign/Form';
import { HumanDesignStructure } from 'common/components/HumanDesign/Structure';
import { Ad } from 'common/components/Ad';

import { selectPageData } from 'utils/selectPageData';

import DefaultPage from '../../Default';

import s from './index.css';

const selectData = createSelector(
  [(state: IAppState) => state.humanDesign],
  (humanDesign) => ({
    humanDesign,
  }),
);

function HumanDesignMainPage() {
  const { humanDesign } = useSelector(selectData);

  const data = useSelector((state: IAppState) =>
    selectPageData(state, 'humandesign', 'main'),
  );

  if (!humanDesign || !data) return null;

  const { content } = data;
  const isMobile = true;

  const {
    title,
    link,
    type,
    is_h2_title: isH2Title,
    is_h3_title: isH3Title,
    isMainCard,
    isSubCard,
    tags,
    likes,
    id,
    draft,
  } = content;

  const draftParsed = draft
    ? parseDraft({
        draftStateInRow: draft,
        id,
        isMobile,
      })
    : '';
  const cardText = (
    <DraftText
      data={draftParsed}
      title={title}
      className={cn(s.draftText, s.draftTextMobile)}
      isMainCard
    />
  );

  return (
    <DefaultPage
      withSchema
      withoutMainCard
      forceFirstTopBanner
      centerColumn={
        <div className={s.card}>
          <CardHeader
            isMobile={isMobile}
            titleProps={{
              title,
              isMobile,
              link,
              type,
              isLink: false,
              isH2Title,
              isH3Title,
              isMainCard,
              isH1Title: true,
              isHanding: false,
              isSubCard,
            }}
            socialProps={{
              links: {},
              isMobile,
            }}
          />
          <HumanDesignForm
            className={s.form}
            isMobile
            isMainPage
            isExperimental={false}
          />
          <Ad name="content1" wrapperClassName={s.ad} />
          <HumanDesignStructure isMobile />
          <HumanDesignTypes
            types={humanDesign.types}
            limit={4}
            ads={{
              0: 'inpage_m',
            }}
            isMobile
          />
          <HumanDesignProfiles
            profiles={humanDesign.profiles}
            limit={4}
            ads={{
              0: 'content2',
            }}
            isMobile
          />
          <Ad name="content3" wrapperClassName={s.ad} />
          {cardText}
          <Tags wrapperClassName={s.tags} tags={tags} isMobile={isMobile} />
          <Footer
            className={cn(s.footer, s.footerMobile)}
            likesClassName={s.likes}
            title={title}
            link={link}
            likes={likes}
            isMobile
            key={likes ? likes.codename : ''}
          />
        </div>
      }
    />
  );
}

export default HumanDesignMainPage;
