import loadable from '@loadable/component';
import { IAppRoute } from 'typings/AppRoute';

import { homeRoutes } from './home';
import { zodiacRoutes } from './zodiac';
import { dreamsRoutes } from './dreams';
import { longreadRoutes } from './longread';
import { moonRoutes } from './moon';
import { sunRoutes } from './sun';
import { fortuneRoutes } from './fortune';
import { editorsRoutes } from './editors';
import { gardenRoutes } from './garden';
import { hairRoutes } from './hair';
import { chineseRoutes } from './chinese';
import { personalRoutes } from './personal';
import { namesRoutes } from './names';
import { primetyRoutes } from './primety';
import { humanDesignRoutes } from './humanDesign';
import { tarotRoutes } from './tarot';
import { newsLetterRoutes } from './newsLetter';

const NotFoundMobile = loadable(() => import('mobile/pages/NotFound'));

export const getMobileRoutes = (): IAppRoute[] => [
  ...homeRoutes,
  ...zodiacRoutes,
  ...dreamsRoutes,
  ...longreadRoutes,
  ...moonRoutes,
  ...sunRoutes,
  ...fortuneRoutes,
  ...editorsRoutes,
  ...gardenRoutes,
  ...hairRoutes,
  ...chineseRoutes,
  ...personalRoutes,
  ...namesRoutes,
  ...primetyRoutes,
  ...humanDesignRoutes,
  ...tarotRoutes,
  ...newsLetterRoutes,
  {
    name: 'notFound',
    path: '**',
    component: NotFoundMobile,
  },
];
