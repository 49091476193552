import React, { Fragment, useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import loadable from '@loadable/component';
import { useLocation } from 'react-router';

import { APP_VERSION } from 'config/constants';
import { SUBSCRIBE_POPUP_TYPE_BY_PAGE } from 'config/constants/news-letter';

import { getGroupedLayout } from 'common/utils/getGroupedLayout';

import { updateCounters } from 'utils/counters/updater';
import { safeGet } from 'utils/safeGet';

import { Ad } from 'common/components/Ad';
import { HairScroll } from 'common/components/Card/@Hair/Scroll';
import { RcmWidget } from 'common/components/RCMWidget';
import { UpdateFeed } from 'common/components/Alerts/UpdateFeed';
import { Typography } from 'common/components/Typography';
import { TabBar } from 'common/components/TabBar';

import { Article } from 'common/components/schemaOrg/Article';

import PwaMobileNotification from 'mobile/components/PwaNotification';
import { TarotMenuWithButton } from 'common/components/Tarot/TarotMenuWithButton';
import SubscribePopupInLayout from 'common/components/NewsLetterSubscribe/SubscribePopup/SubscribePopupInLayout';

import { Top100Context } from 'common/contexts/top100Context';

import { selectPageData } from 'utils/selectPageData';

import s from './styles.css';

const PER_LAYOUT_WEIGHT = 200;

const Card = loadable(() => import('common/components/Card'));

const selectRuntimeData = createSelector(
  [
    (state: IAppState) => state.runtime.top100,
    (state: IAppState) => state.runtime.currentPage,
    (state: IAppState) => state.runtime.currentSection,
    (state: IAppState) => state.runtime.hasNextPage,
    (state: IAppState) => state.meta.seo_text,
  ],
  (
    top100,
    currentPage,
    currentSection,
    hasNextPage,
    seoText,
    // eslint-disable-next-line max-params
  ) => ({
    top100,
    currentPage,
    currentSection,
    hasNextPage,
    seoText,
  }),
);

const orderedBanners = [
  'listing1',
  'listing2',
  'listing3',
  'listing4',
  'top_banner',
];

export interface LayoutPropsType {
  /**
   * Содержмое блока контента.
   * Размещается после основной карточки и до дополнительных карточек
   */
  centerColumn?: JSX.Element;
  /**
   * Флаг главной страницы
   */
  isHomePage?: boolean;
  /**
   * Нужна ли разметка schema
   */
  withSchema?: boolean;
  /**
   * Нужна ли главная карточка
   */
  withoutMainCard?: boolean;
  /**
   * Нужно ли меню карт таро
   */
  withTarotMenu?: boolean;
  /**
   * Нужно ли после первой карточки или centerColumn показывать top_banner
   */
  forceFirstTopBanner?: boolean;
  /**
   * Нужен ли попап подписки
   */
  withSubscribePopup?: boolean;
}

function Layout({
  centerColumn,
  isHomePage,
  withSchema,
  withoutMainCard,
  withTarotMenu,
  forceFirstTopBanner,
  withSubscribePopup,
}: LayoutPropsType) {
  const { top100, currentPage, currentSection, hasNextPage, seoText } =
    useSelector(selectRuntimeData);
  const data = useSelector((state: IAppState) =>
    selectPageData(state, currentPage, currentSection),
  );

  const countersUpdateList = useRef<Record<string, boolean>>({});

  const location = useLocation<{ loading?: boolean }>();

  let topBannerCount = 1;

  const mainTitle = safeGet(() => data.pageTitle);
  const mainCardData: ICardProps = safeGet(() => data.content);
  const otherCardsData: ICardProps[] = safeGet(() => data.cards);
  const longreadTopicList = safeGet(() => data?.topics);

  const isMainCardLongreadDetail = mainCardData
    ? mainCardData.type === 'longread_detail'
    : false;
  const isHairCard = currentPage === 'hair';
  const isHairDate = currentPage === 'hair' && currentSection === 'date';
  /** При клиентском роутинге нужно ждать когда обновятся данные,
   * иначе какое-то время будут отображаться данные предыдущего роута
   */
  const cardDataLoading = Boolean(location.state?.loading);

  const mainCardType = safeGet(() => mainCardData.type);

  const byBillboard = useMemo(
    () =>
      getGroupedLayout({
        mainCardType: mainCardData ? 'detail' : mainCardType,
        otherCardsData,
        orderedBanners,
        perLayoutWeight: PER_LAYOUT_WEIGHT,
        isMobile: true,
      }),
    [mainCardData, mainCardType, otherCardsData],
  );

  const top100Prefix = `${top100}_${mainCardData ? 'content_page' : 'list_page'}`;

  const top100PrefixMemo = useMemo(() => ({ top100Prefix }), [top100Prefix]);
  const top100UserMemo = useMemo(
    () => ({ top100Prefix: `${top100Prefix}::user_profile` }),
    [top100Prefix],
  );
  const top100MainCardMemo = useCallback(
    (top100Page, cardNumber) => ({
      top100Prefix: `${top100Prefix}::content_card::page${top100Page}_card${cardNumber}`,
    }),
    [top100Prefix],
  );
  const top100CardMemo = useCallback(
    ({
      isStoriesCard,
      group,
      top100Page,
      cardNumber,
      type,
      storiesCardNumber,
    }) => {
      if (isStoriesCard) {
        return {
          top100Prefix: `${top100Prefix}::${group}::page${top100Page}_card${cardNumber}::${type}::${storiesCardNumber}`,
        };
      }

      return {
        top100Prefix: `${top100Prefix}::${group}::page${top100Page}_card${cardNumber}`,
      };
    },
    [top100Prefix],
  );

  if (!byBillboard.length) return null;
  let cardNumber = mainCardData ? 1 : 0;
  let otherCardsCount = 0;
  let storiesCardNumber = 0;

  let fullCardIndex = mainCardData ? 1 : 0;
  let rcmWidgetRendered = false;

  const divProps = withSchema
    ? {
        itemScope: true,
        // eslint-disable-next-line sonarjs/no-clear-text-protocols
        itemType: 'http://schema.org/Article',
      }
    : {};

  return (
    <div {...divProps} className={s.fixedWidth}>
      {withTarotMenu && (
        <Top100Context.Provider value={top100PrefixMemo}>
          <TarotMenuWithButton />
        </Top100Context.Provider>
      )}

      {withSchema && <Article />}

      {byBillboard.map((layoutPage, layoutPageIndex) => {
        const isFirstLayoutPage = layoutPageIndex === 0;

        const { cards, ads } = layoutPage;

        const lastAd = ads[ads.length - 1];

        const key = `${currentPage}-${currentSection}-${layoutPageIndex}-${cards.length}-${ads.length}`;

        const hideVeryLastFullSizeBanner =
          !hasNextPage && layoutPageIndex + 1 === byBillboard.length;
        const endByTopBanner = lastAd.name === 'top_banner';

        if (endByTopBanner) {
          topBannerCount += 1;
        }

        const top100Page = endByTopBanner ? topBannerCount - 1 : topBannerCount;

        const needRenderCenterColumn =
          !cardDataLoading &&
          ((mainCardData && isFirstLayoutPage && centerColumn) ||
            (withoutMainCard && isFirstLayoutPage && centerColumn));

        const needRenderTopBannerAfterFirstCard =
          (mainCardData && // значит это детальная страница
            isFirstLayoutPage && // первый элемент
            cards.length === 0 && // нет карточек перед баннером
            ads.length === 1 && // единственный баннер
            ads[0].name === 'top_banner' && // это именно top_banner
            currentPage !== 'humandesign' && // это не дч
            currentPage !== 'hair' && // это не стрижки
            !withoutMainCard) || // есть главная карточка
          // или есть цетральная колонка и нужен top_banner после неё
          (needRenderCenterColumn && forceFirstTopBanner);

        let title = null;

        if (!isHomePage && (mainTitle || seoText) && isFirstLayoutPage) {
          title = mainTitle ? (
            <Typography
              variant="defaultMedium"
              component="h1"
              className={s.title}
            >
              {mainTitle}
            </Typography>
          ) : (
            <Typography variant="defaultMedium" className={s.title}>
              {seoText}
            </Typography>
          );
        }

        return (
          <Fragment key={key}>
            {isFirstLayoutPage && (
              <Top100Context.Provider value={top100UserMemo}>
                <UpdateFeed isHomePage={isHomePage} />
              </Top100Context.Provider>
            )}
            {title}
            {!cardDataLoading &&
              mainCardData &&
              isFirstLayoutPage &&
              !withoutMainCard && (
                <Top100Context.Provider
                  value={top100MainCardMemo(top100Page, cardNumber)}
                >
                  <Card
                    {...mainCardData}
                    isMainCard
                    isHairCard={isHairCard}
                    isHairDate={isHairDate}
                    isLazy={false}
                    isNeedLongreadTopicList={false}
                  />
                  <PwaMobileNotification variant="card" />
                </Top100Context.Provider>
              )}
            {needRenderCenterColumn ? (
              <Top100Context.Provider value={top100PrefixMemo}>
                {centerColumn}
              </Top100Context.Provider>
            ) : null}
            {isHairCard &&
              currentSection === 'main' &&
              isFirstLayoutPage &&
              !withoutMainCard && (
                <Top100Context.Provider value={top100PrefixMemo}>
                  <HairScroll />
                </Top100Context.Provider>
              )}
            {needRenderTopBannerAfterFirstCard && (
              <Ad
                wrapperClassName={s.banner}
                name="top_banner"
                bannerCount={topBannerCount}
                isLazy
                onRender={() => {
                  if (!countersUpdateList.current.afterFirstCard) {
                    countersUpdateList.current.afterFirstCard = true;
                    updateCounters(APP_VERSION.MOBILE);
                  }
                }}
              />
            )}
            {cards.map((cardsGroup, cardsGroupIndex: number) => {
              const { name } = ads[cardsGroupIndex];
              const isVeryLastCard = cardsGroupIndex + 1 === cards.length;
              const cardsGroupKey = `${key}-${cardsGroupIndex}`;
              const needRenderBanner = !(
                hideVeryLastFullSizeBanner && isVeryLastCard
              );

              return (
                <Fragment key={cardsGroupKey}>
                  {cardsGroup.map((item, cardIndex: number) => {
                    const isLazy =
                      isMainCardLongreadDetail ||
                      !isFirstLayoutPage ||
                      (isFirstLayoutPage && cardsGroupIndex > 0) ||
                      (isFirstLayoutPage &&
                        cardsGroupIndex === 0 &&
                        cardIndex >= 2);

                    const isStoriesCard = item.type === 'stories';

                    if (item.type !== 'mini' && !isStoriesCard) {
                      fullCardIndex += 1;
                    }

                    cardNumber += 1;

                    if (item.type !== 'handing_title') {
                      otherCardsCount += 1;
                    }

                    if (isStoriesCard) {
                      storiesCardNumber += 1;
                    }

                    const isNeedLongreadTopicList =
                      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                      isFirstLayoutPage && otherCardsCount === 3;

                    const isH1Title =
                      cardIndex === 0 &&
                      cardsGroupIndex === 0 &&
                      isHomePage &&
                      !mainCardData &&
                      isFirstLayoutPage;

                    let needRenderRcmWidget = false;

                    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                    if (fullCardIndex === 3 && !rcmWidgetRendered) {
                      rcmWidgetRendered = true;
                      needRenderRcmWidget = true;
                    }

                    return (
                      <Top100Context.Provider
                        key={item.id}
                        value={top100CardMemo({
                          isStoriesCard,
                          group: item.group,
                          top100Page,
                          cardNumber,
                          type: item.type,
                          storiesCardNumber,
                        })}
                      >
                        <Card
                          {...item}
                          isMainCard={false}
                          isLazy={isLazy}
                          isH1Title={isH1Title}
                          isFirstStory={
                            isStoriesCard && storiesCardNumber === 1
                          }
                          title={
                            isH1Title && mainTitle ? mainTitle : item.title
                          }
                          isNeedLongreadTopicList={isNeedLongreadTopicList}
                          longreadTopicList={longreadTopicList}
                        />
                        {needRenderRcmWidget ? <RcmWidget /> : null}
                      </Top100Context.Provider>
                    );
                  })}
                  {needRenderBanner && name && name !== 'top_banner' && (
                    <Ad wrapperClassName={s.banner} name={name} isLazy />
                  )}
                  {needRenderBanner && name === 'top_banner' && (
                    <Ad
                      wrapperClassName={s.banner}
                      name="top_banner"
                      bannerCount={topBannerCount}
                      isLazy
                      onRender={() => {
                        if (
                          endByTopBanner &&
                          !countersUpdateList.current[layoutPageIndex]
                        ) {
                          countersUpdateList.current[layoutPageIndex] = true;
                          updateCounters(APP_VERSION.MOBILE);
                        }
                      }}
                    />
                  )}
                </Fragment>
              );
            })}
          </Fragment>
        );
      })}
      <Top100Context.Provider value={top100PrefixMemo}>
        <TabBar />
      </Top100Context.Provider>

      {withSubscribePopup && (
        <SubscribePopupInLayout
          type={SUBSCRIBE_POPUP_TYPE_BY_PAGE[currentPage]}
        />
      )}
    </div>
  );
}

Layout.defaultProps = {
  centerColumn: null,
  isHomePage: false,
  withSchema: false,
  withoutMainCard: false,
  withTarotMenu: false,
  forceFirstTopBanner: false,
  withSubscribePopup: false,
};

export { Layout };
