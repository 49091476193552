import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import DefaultPage from 'mobile/pages/Default';
import { CardHeader } from 'common/components/Card/Header';
import { Footer } from 'common/components/Card/Footer';
import { TarotCardPreview } from 'common/components/Tarot/TarotCardPreview';
import { TableOfContents } from 'common/components/TableOfContents';
import { DraftText } from 'common/components/DraftText';
import { Tags } from 'common/components/Card/Tags';
import { FeedbackForm } from 'common/components/FeedbackForm';
import { Ad } from 'common/components/Ad';

import { selectPageData } from 'utils/selectPageData';

import { parseDraft } from 'common/utils/draftParser';
import { getCurrentTarot } from 'config/constants/tarot';

import s from './styles.css';

const selectCurrentCard = createSelector(
  (state: IAppState) => state.runtime.currentParams.url,
  (url) => getCurrentTarot('code', url),
);

function TarotCardPage() {
  const data = useSelector((state: IAppState) =>
    selectPageData(state, 'fortune', 'taroonecard'),
  );
  const currentCard = useSelector(selectCurrentCard);

  if (!data || !currentCard) return null;

  const { content } = data;
  const isMobile = true;

  const { title, link, likes, draft, tags, id, type } = content;

  const draftParsed = draft
    ? parseDraft({
        draftStateInRow: draft,
        id,
        isMobile,
        withAnchors: true,
      })
    : '';

  return (
    <DefaultPage
      withSchema
      withoutMainCard
      withTarotMenu
      centerColumn={
        <>
          <div className={s.card}>
            <CardHeader
              isMobile={isMobile}
              titleProps={{
                title: `${currentCard.name} Таро – значение и сочетание карты`,
                isMobile,
                type,
                isLink: false,
                isMainCard: true,
                isH1Title: true,
                isHanding: false,
              }}
              socialProps={{
                isMobile,
              }}
            />
            <TarotCardPreview id={currentCard.id} isMobile={isMobile} />
            <TableOfContents
              draft={draft}
              isMobile={isMobile}
              className={s.tableOfContents}
            />
            <DraftText
              data={draftParsed}
              title={title}
              className={s.draftText}
              isMainCard
            />
            <Tags wrapperClassName={s.tags} tags={tags} isMobile={isMobile} />
            <Footer
              title={title}
              link={link}
              likes={likes}
              isMobile={isMobile}
              className={s.footer}
              key={likes ? likes.codename : ''}
            />
          </div>

          <Ad name="top_banner" wrapperClassName={s.ad} />

          <FeedbackForm isCard />
        </>
      }
    />
  );
}

export default TarotCardPage;
