import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { Ad } from 'common/components/Ad';
import DefaultPage from 'mobile/pages/Default';
import { TarotToday } from 'common/components/Tarot/TarotToday';
import { Tags } from 'common/components/Card/Tags';
import { Footer } from 'common/components/Card/Footer';
import { FeedbackForm } from 'common/components/FeedbackForm';
import { PersonalTarotTodayForm } from 'common/components/Tarot/PersonalTarotTodayForm';

import { selectPageData } from 'utils/selectPageData';

import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.tarot.today?.card,
    (state: IAppState) => state.tarot.today?.text,
  ],
  (card, text) => ({
    card,
    text,
  }),
);

function TarotMainPage() {
  const { card, text } = useSelector(selectData);

  const data = useSelector((state: IAppState) =>
    selectPageData(state, 'fortune', 'taromain'),
  );

  if (typeof card !== 'number' || !text || !data) return null;

  const { content } = data;
  const isMobile = true;

  const { title, link, tags, likes } = content;

  return (
    <DefaultPage
      withSchema
      withoutMainCard
      withTarotMenu
      centerColumn={
        <>
          <div className={s.card}>
            <TarotToday cardId={card} draft={text} isMobile={isMobile} />
            <Tags wrapperClassName={s.tags} tags={tags} isMobile={isMobile} />
            <Footer
              className={s.footer}
              title={title}
              link={link}
              likes={likes}
              isMobile={isMobile}
              key={likes ? likes.codename : ''}
            />
          </div>

          <PersonalTarotTodayForm isMobile={isMobile} />

          <Ad name="top_banner" wrapperClassName={s.ad} />

          <FeedbackForm isCard />
        </>
      }
    />
  );
}

export default TarotMainPage;
