import React from 'react';
import loadable from '@loadable/component';
import { IAppRoute } from 'typings/AppRoute';

import { fetchPageData } from 'common/redux/pages';

import TarotCardsPage from 'mobile/pages/Tarot/Cards';
import TarotCardPage from 'mobile/pages/Tarot/Card';
import TarotMainPage from 'mobile/pages/Tarot/Main';
import TarotCategoryPage from 'mobile/pages/Tarot/Category';
import TarotGuidePage from 'mobile/pages/Tarot/Guide';
import DefaultPage from 'mobile/pages/Default';
import {
  CODES_BY_CATEGORY,
  TAROT_FORECAST_YEARS,
  getCurrentTarot,
  tarotCardSlugs,
  tarotCategories,
  CODENAMES_BY_TYPE,
} from 'config/constants/tarot';

import { fetchLikesData } from 'common/redux/likes';
import { fetchTarotData } from 'common/redux/tarot';
import { zodiacSigns } from 'config/constants/routes';
import TarotOnlinePage from 'mobile/pages/Tarot/Online';

const GamePage = loadable(() => import('mobile/pages/Game'));

export const tarotRoutes: IAppRoute[] = [
  {
    name: 'fortune-taro-main',
    path: '/taro',
    exact: true,
    component: TarotMainPage,
    fetchData: async ({ dispatch }) => {
      await Promise.all([
        dispatch(
          fetchPageData('fortune', 'taromain', undefined, false, {
            top100: 'tarot_main',
            isMenuBubblesHidden: true,
          }),
        ),
        dispatch(fetchTarotData('today')),
      ]);
    },
  },
  {
    name: 'fortune-taro-online',
    path: '/taro/online',
    exact: true,
    component: TarotOnlinePage,
    fetchData: async ({ dispatch }) => {
      await Promise.all([
        dispatch(
          fetchPageData('fortune', 'taroonline', undefined, false, {
            top100: 'tarot_fortune_main',
            isMenuBubblesHidden: true,
          }),
        ),
        dispatch(fetchLikesData(Object.values(CODENAMES_BY_TYPE).join(','))),
      ]);
    },
  },
  {
    name: 'fortune-taro-future',
    path: '/taro/budushchee',
    exact: true,
    component: GamePage,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData('fortune', 'tarofuture', undefined, false, {
          top100: 'tarot_fortune_future',
          isMenuBubblesHidden: true,
          isBubblesMoved: true,
        }),
      );
    },
  },
  {
    name: 'fortune-taro-future',
    path: '/taro/budushchee/:key/card-:card',
    exact: true,
    component: GamePage,
    fetchData: async ({ dispatch }, { params }) => {
      const { key, card } = params;

      await dispatch(
        fetchPageData(
          'fortune',
          'tarofuture',
          {
            url: '',
            specialKey: key,
            specialCurrent: card,
          },
          false,
          {
            top100: 'tarot_fortune_future',
            isMenuBubblesHidden: true,
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'fortune-taro-love',
    path: '/taro/lyubov',
    exact: true,
    component: GamePage,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData('fortune', 'tarolove', undefined, false, {
          top100: 'tarot_fortune_love',
          isMenuBubblesHidden: true,
          isBubblesMoved: true,
        }),
      );
    },
  },
  {
    name: 'fortune-taro-love',
    path: '/taro/lyubov/:key/card-:card',
    exact: true,
    component: GamePage,
    fetchData: async ({ dispatch }, { params }) => {
      const { key, card } = params;

      await dispatch(
        fetchPageData(
          'fortune',
          'tarolove',
          {
            url: '',
            specialKey: key,
            specialCurrent: card,
          },
          false,
          {
            top100: 'tarot_fortune_love',
            isMenuBubblesHidden: true,
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'fortune-taro-short',
    path: '/taro/da-net',
    exact: true,
    component: GamePage,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData('fortune', 'taroshort', undefined, false, {
          top100: 'tarot_fortune_yes_no',
          isMenuBubblesHidden: true,
          isBubblesMoved: true,
        }),
      );
    },
  },
  {
    name: 'fortune-taro-short',
    path: '/taro/da-net/:key/card-:card',
    exact: true,
    component: GamePage,
    fetchData: async ({ dispatch }, { params }) => {
      const { key, card } = params;

      await dispatch(
        fetchPageData(
          'fortune',
          'taroshort',
          {
            url: '',
            specialKey: key,
            specialCurrent: card,
          },
          false,
          {
            top100: 'tarot_fortune_yes_no',
            isMenuBubblesHidden: true,
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'fortune-taro-birthdate',
    path: '/taro/data-rozhdeniya',
    exact: true,
    component: GamePage,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData('fortune', 'tarobirthdate', undefined, false, {
          top100: 'tarot_fortune_birthdate',
          isMenuBubblesHidden: true,
        }),
      );
    },
  },
  {
    name: 'fortune-taro-birthdate',
    path: '/taro/data-rozhdeniya/:key/card-:card',
    exact: true,
    component: GamePage,
    fetchData: async ({ dispatch }, { params }) => {
      const { key, card } = params;

      await dispatch(
        fetchPageData(
          'fortune',
          'tarobirthdate',
          {
            url: '',
            specialKey: key,
            specialCurrent: card,
          },
          false,
          {
            top100: 'tarot_fortune_birthdate',
            isMenuBubblesHidden: true,
          },
        ),
      );
    },
  },
  {
    name: 'fortune-taro-guide',
    path: '/taro/samostoyatelno',
    exact: true,
    component: TarotGuidePage,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData('fortune', 'taroguide', undefined, false, {
          top100: 'tarot_guide',
          isMenuBubblesHidden: true,
        }),
      );
    },
  },
  {
    name: 'fortune-taro-cards',
    path: '/taro/znachenie-kart',
    exact: true,
    component: TarotCardsPage,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData('fortune', 'tarocards', undefined, false, {
          top100: 'tarot_meaning_main',
          isMenuBubblesHidden: true,
        }),
      );
    },
  },
  {
    name: 'fortune-taro-category',
    path: `/taro/znachenie-kart/:category(${tarotCategories})`,
    exact: true,
    component: TarotCategoryPage,
    fetchData: async ({ dispatch }, { params }) => {
      const { category } = params;
      const url = CODES_BY_CATEGORY[category as keyof typeof CODES_BY_CATEGORY];

      await dispatch(
        fetchPageData('fortune', 'tarocategory', { url }, false, {
          top100: 'tarot_meaning_suit',
          isMenuBubblesHidden: true,
        }),
      );
    },
  },
  {
    name: 'fortune-taro-one-card',
    path: `/taro/znachenie-kart/:slug(${tarotCardSlugs})`,
    exact: true,
    component: TarotCardPage,
    fetchData: async ({ dispatch }, { params }) => {
      const { slug } = params;
      const { code } = getCurrentTarot('slug', slug) || {};

      await dispatch(
        fetchPageData('fortune', 'taroonecard', { url: code }, undefined, {
          top100: 'tarot_meaning_card',
          isMenuBubblesHidden: true,
        }),
      );
    },
  },
  {
    name: 'fortune-taro-forecast',
    path: `/taro/goroskop/:year(${TAROT_FORECAST_YEARS})?`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const currentYear = new Date().getFullYear();
      const { year } = params;

      await dispatch(
        fetchPageData(
          'fortune',
          'taroforecast',
          { url: year || currentYear },
          false,
          {
            top100: 'tarot_horo',
          },
        ),
      );
    },
  },
  {
    name: 'fortune-taro-forecast-aries',
    path: `/taro/goroskop/:sign(${zodiacSigns})/:year(${TAROT_FORECAST_YEARS})?`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const currentYear = new Date().getFullYear();
      const { sign, year = currentYear } = params;

      await dispatch(
        fetchPageData(
          'fortune',
          'taroforecastbysign',
          {
            url: `${sign}/${year}`,
            zodiacSign: sign,
          },
          false,
          {
            top100: 'tarot_horo',
          },
        ),
      );
    },
  },
];
