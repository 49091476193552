import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { Ad } from 'common/components/Ad';

import { useFetchNextPage } from 'common/hooks/useFetchNextPage';

import s from './index.css';

const HEIGHT_BEFORE_FETCH_NEXT_CARDS = 400;

const selectData = createSelector(
  [(state: IAppState) => state.runtime.hasNextPage],
  (hasNextPage) => ({
    hasNextPage,
  }),
);

function Footer() {
  const fetchPointRef = useRef(null);
  const { hasNextPage } = useSelector(selectData);

  useFetchNextPage(fetchPointRef, HEIGHT_BEFORE_FETCH_NEXT_CARDS);

  return (
    <>
      <div ref={fetchPointRef} />
      {!hasNextPage && (
        <>
          <Ad className={s.adFooter} name="footer" />
          <div className={s.emptyFooter} />
        </>
      )}
    </>
  );
}

export { Footer };
