import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { CardHeader } from 'common/components/Card/Header';
import { Footer } from 'common/components/Card/Footer';
import { Tags } from 'common/components/Card/Tags';
import { HumanDesignDetailed } from 'common/components/HumanDesign/Detailed';
import { HumanDesignBubbles } from 'common/components/HumanDesign/Bubbles';
import { HumanDesignForm } from 'common/components/HumanDesign/Form';
import { HumanDesignProfiles } from 'common/components/HumanDesign/Profiles';
import { Ad } from 'common/components/Ad';

import { selectPageData } from 'utils/selectPageData';

import DefaultPage from '../../Default';

import s from './index.css';

const selectData = createSelector(
  [(state: IAppState) => state.humanDesign],
  (humanDesign) => ({
    humanDesign,
  }),
);

function HumanDesignTypePage() {
  const { humanDesign } = useSelector(selectData);

  const data = useSelector((state: IAppState) =>
    selectPageData(state, 'humandesign', 'type'),
  );

  if (!humanDesign || !data) return null;

  const { content } = data;
  const isMobile = true;

  const {
    title,
    link,
    type,
    is_h2_title: isH2Title,
    is_h3_title: isH3Title,
    isMainCard,
    isSubCard,
    tags,
    likes,
  } = content;

  return (
    <DefaultPage
      withSchema
      withoutMainCard
      forceFirstTopBanner
      centerColumn={
        <div className={s.card}>
          <CardHeader
            isMobile={isMobile}
            titleProps={{
              title,
              isMobile,
              link,
              type,
              isLink: false,
              isH2Title,
              isH3Title,
              isMainCard,
              isH1Title: true,
              isHanding: false,
              isSubCard,
            }}
            socialProps={{
              links: {},
              isMobile,
            }}
          />
          <HumanDesignDetailed
            detailed={humanDesign.detailed}
            type="types"
            isMobile
          />
          <Ad name="content3" wrapperClassName={s.ad} />
          <HumanDesignBubbles list={humanDesign.types} type="types" isMobile />
          <HumanDesignProfiles
            profiles={humanDesign.profiles}
            className={s.profiles}
            limit={2}
            isMobile
          />
          <HumanDesignForm isMobile isExperimental={false} />
          <Tags wrapperClassName={s.tags} tags={tags} isMobile={isMobile} />
          <Footer
            className={cn(s.footer, s.footerMobile)}
            likesClassName={cn(s.likes, s.likesMobile)}
            title={title}
            link={link}
            likes={likes}
            isMobile
            key={likes ? likes.codename : ''}
          />
        </div>
      }
    />
  );
}

export default HumanDesignTypePage;
