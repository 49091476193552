import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router';

import { Layout } from 'mobile/components/Layout';
import { SignMenu } from 'common/components/SignMenu';

import { useTop100Context } from 'common/contexts/top100Context';

const selectData = createSelector(
  [(state: IAppState) => state.runtime.reloadKey],
  (reloadKey) => ({
    reloadKey,
  }),
);

interface MatchParams {
  type: 'man' | 'woman' | 'child' | 'element';
}

type Props = RouteComponentProps<MatchParams>;

function SignDescriptionType({ match }: Props) {
  const { reloadKey } = useSelector(selectData);
  const { top100Prefix } = useTop100Context();

  if (match.params.type === 'element') {
    return <Layout withSchema />;
  }

  return (
    <Layout
      withSchema
      centerColumn={
        <SignMenu
          key={`sign-description-type-${reloadKey}`}
          top100Prefix={top100Prefix}
          type="description"
        />
      }
    />
  );
}

export default SignDescriptionType;
