import React, { Fragment, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { getDate, isValid, isSameMonth } from 'date-fns';

import { getAdForInnerBlocks } from 'config/constants/content';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import DefaultPage from 'mobile/pages/Default';
import { CardHeader } from 'common/components/Card/Header';
import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';

import { HairDays } from 'common/components/Card/@Hair/Days';
import { HairLink } from 'common/components/Card/@Hair/Link';
import { Bubbles } from 'common/components/Card/Bubbles';

import Card from 'common/components/Card';
import { CalendarContext } from 'common/components/Card/CalendarContext';
import { Calendar } from 'common/components/Card/Calendar';
import { Tags } from 'common/components/Card/Tags';
import { Footer } from 'common/components/Card/Footer';

import rootStyles from 'common/components/Card/styles.css';
import s from './index.css';

function HairPeriodPage() {
  const { top100Prefix } = useTop100Context();

  const data = useSelector((state: IAppState) => state.pages.hair.period);
  const [calendarViewOpened, setCalendarViewOpened] = useState(true);
  const [isCutOpened, setIsCutOpened] = useState(false);

  const dataDate = new Date(data.content.date as Date);
  const showCut =
    isValid(dataDate) &&
    isSameMonth(new Date(), dataDate) &&
    getDate(new Date()) > 1 &&
    getDate(new Date()) >= getDate(dataDate) &&
    !isCutOpened;

  const calendarContextMemo = useMemo(
    () => [calendarViewOpened, setCalendarViewOpened],
    [calendarViewOpened],
  );

  const days = useMemo(() => {
    if (showCut) {
      const currentDay = getDate(new Date());

      return data.content.inner_blocks?.filter((block) => {
        if (block.date) {
          const blockDate = getDate(new Date(block.date));

          return isValid(blockDate) ? blockDate >= currentDay : true;
        }
      });
    }

    return data.content.inner_blocks;
  }, [data.content.inner_blocks, showCut]);

  const handleCutClick = () => {
    setIsCutOpened(true);
  };

  const { content } = data;
  const isMobile = true;

  const {
    id,
    title,
    link,
    type,
    is_h2_title: isH2Title,
    is_h3_title: isH3Title,
    isMainCard,
    isSubCard,
    bubbles,
    calendar,
    tags,
    likes,
    inner_blocks: innerBlocks,
  } = content;

  return (
    <DefaultPage
      withoutMainCard
      centerColumn={
        <div className={cn(rootStyles.root, rootStyles.rootMobile)}>
          <CardHeader
            isMobile={isMobile}
            titleProps={{
              title,
              isMobile,
              link,
              type,
              isLink: false,
              isH2Title,
              isH3Title,
              isMainCard,
              isH1Title: true,
              isHanding: false,
              isSubCard,
            }}
            socialProps={{
              links: {},
              isMobile,
            }}
          />
          <HairLink />
          <Bubbles
            className={cn(
              rootStyles.bubbles,
              rootStyles.bubblesMobile,
              rootStyles.bubblesMenu,
            )}
            bubbles={bubbles}
            isCardBubblesMoved
          />
          {calendar && (
            <CalendarContext.Provider value={calendarContextMemo}>
              <Calendar
                className={cn(rootStyles.calendar, rootStyles.calendarMobile)}
                calendar={calendar}
                isMobile={isMobile}
              />
            </CalendarContext.Provider>
          )}
          {showCut && !isCutOpened && (
            <div className={s.cut}>
              <Typography
                variant="defaultBold"
                className={s.action}
                element={<button type="button" onClick={handleCutClick} />}
                {...getTop100Markup(
                  isMobile,
                  top100Prefix,
                  'calendar_cut_opened',
                )}
              >
                <Icon id="3-dots" className={s.dots} />
                Показать предыдущие дни
              </Typography>
            </div>
          )}
          {days?.map((card: Partial<ICardProps>, cardIndex) => {
            const key = `${id}-${cardIndex}`;
            const isFirstCard = cardIndex === 0;
            const ad = getAdForInnerBlocks({
              bannerIndexes: {
                0: 'content1',
                4: 'inpage_m',
                8: 'content_spec',
                13: 'content2',
                17: 'content3',
              },
              isMobile: true,
              index: cardIndex,
              length: days.length,
              // eslint-disable-next-line @typescript-eslint/no-magic-numbers
              rule: cardIndex >= 22 && (cardIndex - 22) % 4 === 0,
            });

            return (
              <Fragment key={key}>
                <Card
                  id={`${key}-card`}
                  type="widgets_with_inner_blocks_sub"
                  isMainCard={false}
                  isSubCard
                  isHairSubCardHighlight={isFirstCard}
                  title=""
                  {...card}
                />
                {isFirstCard && <HairDays innerBlocks={innerBlocks} />}
                {ad}
              </Fragment>
            );
          })}
          <Tags
            wrapperClassName={cn(rootStyles.tags, rootStyles.tagsMobile)}
            tags={tags}
            isMobile={isMobile}
          />
          <Footer
            className={cn(rootStyles.footer, rootStyles.footerMobile)}
            likesClassName={cn(rootStyles.likes, rootStyles.likesMobile)}
            title={title}
            link={link}
            likes={likes}
            isMobile={isMobile}
            key={likes ? likes.codename : ''}
          />
        </div>
      }
      forceFirstTopBanner
    />
  );
}

export default HairPeriodPage;
