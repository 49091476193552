import React from 'react';

import { Layout } from 'mobile/components/Layout';

interface DefaultPageType {
  withSchema?: boolean;
  withoutMainCard?: boolean;
  withTarotMenu?: boolean;
  isHomePage?: boolean;
  centerColumn?: JSX.Element;
  forceFirstTopBanner?: boolean;
  withSubscribePopup?: boolean;
}

function DefaultPage({
  withSchema,
  withoutMainCard,
  withTarotMenu,
  isHomePage,
  centerColumn,
  forceFirstTopBanner,
  withSubscribePopup,
}: DefaultPageType) {
  return (
    <Layout
      withSchema={withSchema}
      withoutMainCard={withoutMainCard}
      withTarotMenu={withTarotMenu}
      isHomePage={isHomePage}
      centerColumn={centerColumn}
      forceFirstTopBanner={forceFirstTopBanner}
      withSubscribePopup={withSubscribePopup}
    />
  );
}

DefaultPage.defaultProps = {
  withSchema: false,
  withoutMainCard: false,
  withTarotMenu: false,
  isHomePage: false,
  centerColumn: undefined,
  forceFirstTopBanner: false,
  withSubscribePopup: false,
};

export default DefaultPage;
