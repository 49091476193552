import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { Route, Redirect } from 'react-router-dom';
import { AdProvider } from '@rambler-components/ad';
import Snackbar from '@rambler-components/snackbar';

import {
  TOP100_ID,
  TOP100_COMMON_ID,
  TOP100_RAMBLER_ID,
  RCM_ID,
} from 'config/constants/counters';

import { IAppRoute } from 'typings/AppRoute';

import { getMobileRoutes } from 'mobile/routes';

import { Head } from 'common/components/Head';
import { AdblockDetect } from 'common/components/AdblockDetect';
import { PageSwitch } from 'common/components/PageSwitch';

import { TopPart } from 'mobile/components/TopPart';
import { Footer } from 'mobile/components/Footer';

import { top100Wrapper } from 'utils/counters/top100Wrapper';
import { splitsToArray } from 'common/utils/splitsToArray';

import { useSendRcmPageView } from 'common/hooks/useSendRcmPageView';

import { STRICT_CONTENT_PAGES } from 'config/constants/routes';

import 'sanitize.css';
import 'css/base.css';
import '@rambler-components/button/styles.css';
import '@rambler-components/tooltip/styles.css';
import '@rambler-components/calendar/styles.css';
import '@rambler-components/field-status/styles.css';
import '@rambler-components/geo/styles.css';
import '@rambler-components/input/styles.css';
import '@rambler-components/loader/styles.css';
import '@rambler-components/popup/styles.css';
import '@rambler-components/radio-button/styles.css';
import '@rambler-components/search/styles.css';
import '@rambler-components/select/styles.css';
import '@rambler-components/snackbar/styles.css';
import '@rambler-components/tabs/styles.css';
import '@rambler-components/textarea/styles.css';
import '@rambler-components/checkbox/styles.css';
import '@rambler-components/hint/styles.css';

import 'mobile/css/variables.css';
import 'mobile/css/root.css';
import { SET_RUNTIME_VARIABLE } from 'common/redux/runtime';
import { getPWADisplayMode } from 'common/utils/get-pwa-display-mode';

const selectPadAndDebug = createSelector(
  [
    (state: IAppState) => state.runtime.banners.padID,
    (state: IAppState) => state.runtime.isDebug,
    (state: IAppState) => state.runtime.currentParams.isFullScreen,
    (state: IAppState) => state.runtime.ruid,
    (state: IAppState) => state.runtime.horoSplit,
    (state: IAppState) => state.runtime.splits,
    (state: IAppState) => state.runtime.currentPage,
    (state: IAppState) => state.runtime.ramblerId,
  ],
  (
    padID,
    isDebug,
    isFullScreen,
    ruid,
    horoSplit,
    splits,
    currentPage,
    ramblerId,
    // eslint-disable-next-line max-params
  ) => ({
    padID,
    isDebug,
    isFullScreen,
    ruid,
    horoSplit,
    splits,
    currentPage,
    ramblerId,
  }),
);

function App() {
  const dispatch = useDispatch();
  const {
    isDebug,
    padID,
    isFullScreen,
    ruid,
    horoSplit,
    splits,
    currentPage,
    ramblerId,
  } = useSelector(selectPadAndDebug);

  useSendRcmPageView();

  const routes = getMobileRoutes();

  useEffect(() => {
    dispatch({
      type: SET_RUNTIME_VARIABLE,
      name: 'PWADisplayMode',
      value: getPWADisplayMode(),
    });

    top100Wrapper.init({
      projectIds: [TOP100_ID, TOP100_COMMON_ID, TOP100_RAMBLER_ID],
      pub_id: ruid,
      rambler_id: ramblerId,
      attributes_dataset: [
        'horo-mobile-new',
        'cerber-topline',
        'cerber-topline-mob',
        'cerber',
      ],
      splits: ['new', horoSplit, ...splitsToArray(splits)],
    });

    top100Wrapper.push(() => {
      if (typeof window.rcm === 'function') {
        window.rcm('setup', {
          rcmId: RCM_ID,
          xuid: ruid,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AdProvider
      padId={padID}
      publisherId={ruid}
      publisherScope=".rambler.ru"
      isDebug={__DEV__ || isDebug}
    >
      <Head />
      <AdblockDetect />
      {!isFullScreen && <TopPart />}
      <PageSwitch appRoutes={routes}>
        {(routes as any).map((route: IAppRoute) => {
          if (route.redirect) {
            return (
              <Redirect
                {...route}
                from={route.path}
                to={route.redirect}
                key={route.name}
              />
            );
          }

          return <Route {...route} key={route.name} />;
        })}
      </PageSwitch>
      {!isFullScreen && !STRICT_CONTENT_PAGES.includes(currentPage) && (
        <Footer />
      )}
      <Snackbar />
    </AdProvider>
  );
}

export default App;
